.repay {
  padding: 100px 0 120px;
  &__row {
    display: flex;
    column-gap: 24px;
  }
  &__card {
    width: calc(25% - 18px);
    .lazy-load-image-background.blur.lazy-load-image-loaded {
      width: 100%;
    }
    &-img {
      width: 100%;
    }
    &-title {
      margin: 16px 0;
      @include text(600, 27px, 32px, #000);
    }
    &-list {
      display: flex;
      flex-direction: column;
      padding: 0 16px 0 35px;
      row-gap: 12px;
    }
    &-item {
      list-style-type: disc;
      @include text(normal, 19.5px, 24px, $black);
    }
  }
}

@media (max-width: 1200px) {
  .repay {
    padding: 80px 0 100px;
  }
  .repay__row {
    flex-wrap: wrap;
    row-gap: 36px;
  }
  .repay__card {
    width: calc(50% - 12px);
  }
}

@media (max-width: 1024px) {
  .repay {
    padding: 60px 0 80px;
  }
}

@media (max-width: 820px) {
  .repay {
    padding: 45px 0 65px;
  }
  .repay__card-title {
    margin: 16px 0;
    font-size: 24px;
  }
  .repay__card-item {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .repay {
    padding: 40px 0 56px;
  }
  .repay__card-title {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 24px;
  }
  .repay__card-item {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 500px) {
  .repay__card {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .repay {
    .title {
      margin-bottom: 16px !important;
    }
  }
}
