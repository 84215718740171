.mobileQuestions {
  padding-bottom: 18px;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    color: #060606;
  }
  &__content {
    margin: 30px 0 50px;
    background: #e4f2fe;
    border-radius: 28px;
    padding: 6px 16px 6px;
  }
  &__accordion {
    padding: 13px 0 14px;
    border-bottom: 1px solid #dbe6f0;
    &:last-child {
      border-bottom: none;
    }
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-info {
      font-weight: 600;
      font-size: 15px;
      line-height: 150%;
      color: #21222b;
      display: none;
      &_active {
        display: block;
        margin-top: 16px;
      }
    }
  }
}
