.firstScreenBusiness {
  padding: 218px 0 0;
  background: #edf8f7;

  &__title {
    @include text(bold, 72px, 80px, #141414);
  }
  &__subtitle {
    @include text(normal, 32px, 40px, #303030);
    margin: 40px 0;
  }
  &__content {
    position: relative;
  }
  &__img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
  &__btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 20px;
    cursor: pointer;
    transition: all 0.7s;
    color: black;
    border: 1px solid $yellow;
    background: $yellow;
    border-radius: 6px;
    @include text(600, 20px, 24px, #000);
    margin-bottom: 118px;
  }

  &__btn:hover {
    z-index: 0;
    color: $green;
    border: 1px solid $green;
    background: transparent;
  }

  &__btn span {
    z-index: -1;

    transition: all 0.7s;
  }

  &__btn .first {
    position: absolute;
    top: 0;
    right: 100%;

    width: 25%;
    height: 100%;

    content: '';

    background: #edf8f7;
  }

  &__btn:hover .first {
    top: 0;
    right: 0;
  }

  &__btn .second {
    position: absolute;
    top: -100%;
    left: 25%;

    width: 25%;
    height: 100%;

    content: '';

    background: #edf8f7;
  }

  &__btn:hover .second {
    top: 0;
    left: 50%;
  }

  &__btn .third {
    position: absolute;
    top: 100%;
    left: 50%;

    width: 25%;
    height: 100%;

    content: '';

    background: #edf8f7;
  }

  &__btn:hover .third {
    top: 0;
    left: 25%;
  }

  &__btn .fourth {
    position: absolute;
    top: 0;
    left: 100%;

    width: 25%;
    height: 100%;

    content: '';

    background: #edf8f7;
  }

  &__btn:hover .fourth {
    top: 0;
    left: 0;
  }
}

@media (max-width: 1440px) {
  .firstScreenBusiness__title {
    font-size: 70px;
    line-height: 78px;
  }
}

@media (max-width: 1320px) {
  .firstScreenBusiness__title {
    font-size: 66px;
    line-height: 74px;
  }
  .firstScreenBusiness__subtitle {
    font-size: 30px;
    line-height: 39px;
  }
}

@media (max-width: 1200px) {
  .firstScreenBusiness__img {
    position: absolute;
    top: 78px;
    right: 0;
    height: 85%;
  }
  .firstScreenBusiness__content {
    overflow: hidden;
  }
  .firstScreenBusiness__title {
    font-size: 56px;
    line-height: 60px;
  }
  .firstScreenBusiness__subtitle {
    font-size: 28px;
    line-height: 35px;
  }
}

@media (max-width: 992px) {
  .firstScreenBusiness {
    padding: 190px 0 0;
  }
  .firstScreenBusiness__title {
    font-size: 43px;
    line-height: 50px;
  }
  .firstScreenBusiness__subtitle {
    font-size: 22px;
    line-height: 30px;
    margin: 30px 0;
  }
}

@media (max-width: 820px) {
  .firstScreenBusiness {
    position: relative;
  }
  .firstScreenBusiness__content {
    position: static;
  }
  .firstScreenBusiness {
    padding: 170px 0 0;
  }
  .firstScreenBusiness__img {
    position: absolute;
    top: unset;
    bottom: 0;
    right: 0;
    height: 350px;
  }
}

@media (max-width: 768px) {
  .firstScreenBusiness__title {
    font-size: 39px;
    line-height: 48px;
  }
  .firstScreenBusiness__subtitle {
    font-size: 19px;
    line-height: 24px;
    margin: 20px 0;
  }
  .firstScreenBusiness__btn {
    margin-bottom: 98px;
  }
  .firstScreenBusiness__img {
    height: 335px;
  }
}

@media (max-width: 676px) {
  .firstScreenBusiness__title {
    max-width: 300px;
    br {
      display: none;
    }
  }
  .firstScreenBusiness__subtitle {
    max-width: 300px;
    br {
      display: none;
    }
  }
  .firstScreenBusiness__btn {
    font-size: 18px;
    line-height: 20px;
  }
}

@media (max-width: 576px) {
  .firstScreenBusiness__content {
    overflow: visible;
  }
  .firstScreenBusiness__info {
    width: calc(100% + 48px);
    padding: 20px 24px 0;
    position: relative;
    z-index: 1;
    left: -24px;
    background: linear-gradient(180deg, rgba(237, 248, 247, 0) 0%, rgba(204, 233, 230, 0.92) 17.79%, #baeae5 55.73%);
  }

  .firstScreenBusiness__btn {
    margin-bottom: 24px;
    padding: 15px;
    font-size: 16px;
  }
  .firstScreenBusiness__title {
    max-width: 400px;
    font-size: 30px;
    line-height: 35px;
  }
  .firstScreenBusiness__subtitle {
    max-width: 400px;
    font-size: 20px;
  }
  .firstScreenBusiness {
    padding-top: 307px;
  }
  .firstScreenBusiness__img {
    top: 115px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 425px) {
  .firstScreenBusiness__title {
    font-size: 26px;
    max-width: 100%;
    line-height: 28px;
  }
}
@media (max-width: 375px) {
  .firstScreenBusiness__info {
    width: calc(100% + 44px);
  }
}
