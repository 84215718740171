.questions {
  padding-bottom: 100px;
  &__content {
    display: flex;
    column-gap: 24px;
  }
  &__col {
    width: calc(50% - 12px);
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &__item {
    padding: 0 92px 28px 16px;
    border-bottom: 1px solid #c8c8c8;
    position: relative;
    cursor: pointer;
    &-title {
      @include text(600, 24px, 28px, #000);
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        color: #139daf;
      }
      &_active {
        transition: 0.3s;
        color: #139daf;
      }
    }
    &-icon {
      top: 11px;
      right: 22px;
      position: absolute;
    }
    &-info {
      @include text(normal, 20px, 24px, $black);
      display: none;
      transition: 0.3s;
      &_active {
        transition: 0.3s;
        margin-top: 16px;
        display: block;
      }
    }
  }
}

@media (max-width: 1024px) {
  .questions__item {
    padding: 0 61px 28px 16px;
  }
}

@media (max-width: 992px) {
  .questions__item-title {
    font-size: 22px;
    line-height: 24px;
  }
}
@media (max-width: 820px) {
  .questions {
    padding-bottom: 80px;
    .title {
      margin-bottom: 45px !important;
    }
  }
}

@media (max-width: 768px) {
  .questions__content {
    flex-wrap: wrap;
    row-gap: 16px;
  }
  .questions__col {
    width: 100%;
    row-gap: 16px;
  }

  .questions__item-title {
    font-size: 20px;
    line-height: 24px;
  }
  .questions__item-icon {
    top: 5px;
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
  .questions {
    padding-bottom: 12px;
  }
  .questions__col:last-child .questions__item:last-child {
    border: 0;
  }
}

@media (max-width: 425px) {
  .questions__item {
    padding: 0 55px 15px 0;
  }
  .questions__item-info {
    font-size: 16px;
    line-height: 20px;
  }
  .questions {
    padding: 0 0 40px;
  }
}
