.help {
  padding: 60px 0 100px;
  background: #f8f8f8;
  text-align: center;

  &__row {
    display: flex;
    column-gap: 24px;
  }

  &__col {
    width: calc(33% - 16px);
    text-align: left;
    &-title {
      @include text(600, 64px, 80px, #000);
    }
    &-subtitle {
      @include text(normal, 20px, 28px, $black);
    }
  }
}

@media (max-width: 1200px) {
  .help {
    padding: 40px 0 80px;
  }
  .help__col-title {
    font-size: 50px;
    line-height: 70px;
  }
  .help__col-subtitle {
    font-size: 19px;
    line-height: 26px;
  }
}

@media (max-width: 992px) {
  .help__col-title {
    font-size: 45px;
    line-height: 70px;
  }
}

@media (max-width: 820px) {
  .help__col-title {
    font-size: 42px;
    line-height: 65px;
  }
  .help__col-subtitle {
    font-size: 17px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .help__col-title {
    font-size: 38px;
    line-height: 60px;
  }
  .help__col-subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 676px) {
  .help__row {
    flex-wrap: wrap;
    justify-content: center;
  }
  .help__col {
    width: 100%;
    margin-bottom: 30px;
  }
}
