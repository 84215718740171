@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap');

.containerMob {
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
}

.mobile__btn {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 64px;
  background: #3530fa;
  border-radius: 16px;
  font-weight: 600;
  font-size: 17px;
  line-height: 140%;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.swiper__cont {
  padding-left: 20px;
}

.mobile {
  display: none;
}

@import 'FirstScreenMobile/firstScreenMobile';
@import 'WhereUse/whereUse';
@import 'HowStart/howStart';
@import 'MobileQuestions/mobileQuestions';

@media (max-width: 450px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

@media (max-width: 390px) {
  .mobile__btn {
    width: 250px;
  }
}
@media (max-width: 320px) {
  .mobile__btn {
    width: 220px;
    height: 55px;
  }
}
