.title {
  @include text(bold, 60px, 72px, #000);
}

@media (max-width: 1200px) {
  .title {
    font-size: 56px;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 48px;
  }
}
@media (max-width: 992px) {
  .title {
    font-size: 40px;
  }
}

@media (max-width: 820px) {
  .title {
    font-size: 38px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 425px) {
  .title {
    text-align: center;
  }
}
