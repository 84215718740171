.trust {
  text-align: center;
  padding: 40px 0 80px;
  background: #f8f8f8;

  &__row {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: repeat(6, auto);
    column-gap: 24px;
  }
  &__brandsItem {
    height: 150px;
    &_0 {
      background: url('../../../assets/business/1.png') center/cover no-repeat;
      &:hover,
      &:focus {
        background: url('../../../assets/business/11.png') center/cover no-repeat;
      }
    }
    &_1 {
      background: url('../../../assets/business/2.png') center/cover no-repeat;
      &:hover,
      &:focus {
        background: url('../../../assets/business/22.png') center/cover no-repeat;
      }
    }
    &_2 {
      background: url('../../../assets/business/3.png') center/cover no-repeat;
      &:hover,
      &:focus {
        background: url('../../../assets/business/33.png') center/cover no-repeat;
      }
    }
    &_3 {
      background: url('../../../assets/business/4.png') center/cover no-repeat;
      &:hover,
      &:focus {
        background: url('../../../assets/business/44.png') center/cover no-repeat;
      }
    }
    &_4 {
      background: white url('../../../assets/business/5.png') center/cover no-repeat;
      &:hover,
      &:focus {
        background: white url('../../../assets/business/55.png') center/cover no-repeat;
      }
    }
    &_5 {
      background: url('../../../assets/business/6.png') center/cover no-repeat;
      &:hover,
      &:focus {
        background: url('../../../assets/business/66.png') center/272px 182px no-repeat;
      }
    }
  }
}

@media (max-width: 1320px) {
  .trust__brandsItem {
    height: 130px;
    &_5 {
      &:hover,
      &:focus {
        background: url('../../../assets/business/66.png') center/232px 162px no-repeat;
      }
    }
  }
}

@media (max-width: 1200px) {
  .trust__brandsItem {
    height: 100px;
    &_5 {
      &:hover,
      &:focus {
        background: url('../../../assets/business/66.png') center/182px 127px no-repeat;
      }
    }
  }
}

@media (max-width: 992px) {
  .trust__row {
    column-gap: 16px;
  }
  .trust__brandsItem {
    height: 80px;
    &_5 {
      &:hover,
      &:focus {
        background: url('../../../assets/business/66.png') center/145px 102px no-repeat;
      }
    }
  }
}

@media (max-width: 768px) {
  .trust {
    padding: 30px 0 50px;
  }
  .trust__row::-webkit-scrollbar {
    display: none;
  }
  .trust__row {
    overflow-x: scroll;
  }
  .trust__brandsItem {
    width: 110px;
  }
}
