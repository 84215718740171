@font-face {
  font-family: 'Benzin';
  src:
    local('Benzin Bold'),
    local('Benzin-Bold'),
    url('Benzin-Bold.woff2') format('woff2'),
    url('Benzin-Bold.woff') format('woff'),
    url('Benzin-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
