/* This stylesheet generated by Transfonter (https://transfonter.org) on August 23, 2017 4:44 PM */

@font-face {
  font-family: 'Segoe UI';
  src: url('SegoeUI-Light.eot');
  src:
    local('Segoe UI Light'),
    local('SegoeUI-Light'),
    url('SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
    url('SegoeUI-Light.woff') format('woff'),
    url('SegoeUI-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('SegoeUI-BoldItalic.eot');
  src:
    local('Segoe UI Bold Italic'),
    local('SegoeUI-BoldItalic'),
    url('SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('SegoeUI-BoldItalic.woff') format('woff'),
    url('SegoeUI-BoldItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('SegoeUI-SemiBold.eot');
  src:
    local('Segoe UI Semibold'),
    local('SegoeUI-SemiBold'),
    url('SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('SegoeUI-SemiBold.woff') format('woff'),
    url('SegoeUI-SemiBold.ttf') format('truetype');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('SegoeUI.eot');
  src:
    local('Segoe UI'),
    local('SegoeUI'),
    url('SegoeUI.eot?#iefix') format('embedded-opentype'),
    url('SegoeUI.woff') format('woff'),
    url('SegoeUI.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('SegoeUI-Bold.eot');
  src:
    local('Segoe UI Bold'),
    local('SegoeUI-Bold'),
    url('SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
    url('SegoeUI-Bold.woff') format('woff'),
    url('SegoeUI-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('SegoeUI-Italic.eot');
  src:
    local('Segoe UI Italic'),
    local('SegoeUI-Italic'),
    url('SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
    url('SegoeUI-Italic.woff') format('woff'),
    url('SegoeUI-Italic.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: italic;
}
