.docs {
  padding: 150px 0 0;
  position: relative;
  &::before {
    content: '';
    top: 0;
    left: 0;
    height: 100px;
    position: absolute;
    background: #edf8f7;
    width: 100%;
    z-index: -1;
  }
  &__title {
    @include text(700, 60px, 72px, black);
    margin-bottom: 40px;
  }
  &__menu {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-bottom: 25px;
  }
  &__item {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    column-gap: 15px;
  }
  .docs__link {
    @include text(normal, 22px, 28px, black);
  }
}
.choice {
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    p {
      margin-left: 15px;
      font-size: 22px;
      line-height: 28px;
      color: black;
    }
  }
}
@media (max-width: 992px) {
  .docs__title {
    font-size: 50px;
    line-height: 62px;
  }
  .docs {
    .docs__link {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media (max-width: 768px) {
  .docs__title {
    font-size: 40px;
    line-height: 52px;
  }
  .docs {
    padding-top: 120px;
    .docs__link {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@media (max-width: 500px) {
  .docs__title {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .docs {
    .docs__link {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
