.formBlock {
  background: #48b3ab;
  padding: 60px 0;

  &__title {
    @include text(bold, 60px, 72px, #fff);
  }

  &__subtitle {
    margin: 24px 0 20px;
    @include text(normal, 20px, 28px, #fff);
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    column-gap: 20px;

    .first {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 1180px) {
        flex-direction: column;
      }

      .col-6 {
        flex-basis: 50%;
      }

      .col-5 {
        flex-basis: 41.666667%;
      }

      .col-7 {
        flex-basis: 58.333333%;

        input {
          max-width: 500px;
          @media screen and (max-width: 1180px) {
            max-width: 100%;
          }
        }
      }
    }

    .mt-50 {
      margin-top: -50px;
    }

    .second {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__input {
    width: calc(80% - 20px);
    border: 2px solid rgb(218, 240, 238);
    border-radius: 10px;
    padding: 10px 10px 10px 20px;
    line-height: normal !important;
    @include text(normal, 20px, 24px, #919191);
    background: #fff;
    margin-bottom: 10px;
    color: #000;

    &::placeholder {
      color: #000;
    }

    &:focus,
    &:active {
      border-color: rgb(19, 157, 175);
      outline: none;
    }

    @media screen and (max-width: 1180px) {
      width: 100%;
    }
  }

  &__btn {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 58px;
    text-align: center;
    margin: 0 auto;

    cursor: pointer;
    transition: all 0.7s;

    color: #fff;
    border: 1px solid rgb(19, 157, 175);
    border-radius: 8px;
    background: rgb(19, 157, 175);

    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    line-height: 24px;

    @media screen and (max-width: 1180px) {
      max-width: 100%;
    }
  }

  &__btn:hover {
    z-index: 0;

    color: rgb(218, 240, 238);
    border: 2px solid rgb(218, 240, 238);
  }

  &__btn span {
    z-index: -1;

    transition: all 0.7s;
  }

  &__btn .first {
    position: absolute;
    top: 0;
    right: 100%;

    width: 25%;
    height: 100%;

    content: '';

    background: cadetblue;
  }
}

@media (max-width: 1024px) {
  .formBlock__title {
    font-size: 44px;
  }
}

@media (max-width: 992px) {
  .formBlock__title {
    font-size: 38px;
  }
}

@media (max-width: 820px) {
  .formBlock {
    padding: 50px 0;
  }
  .formBlock__title {
    font-size: 36px;
  }
  .formBlock__subtitle {
    margin: 20px 0 20px;
  }
}

@media (max-width: 768px) {
  .formBlock__title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 500px) {
  .formBlock__subtitle {
    font-size: 16px;
    line-height: 20px;
  }
  .formBlock__form {
    column-gap: 5px;
  }
}

@media (max-width: 425px) {
  .formBlock {
    &__form {
      flex-wrap: wrap;
      row-gap: 16px;
    }

    &__input {
      width: 100%;
      height: 40px;
      font-size: 16px;
      line-height: 24px;
    }

    &__btn {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      height: 40px;
    }
  }
}

.appealType {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-width: 0;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  h2 {
    margin-bottom: 10px;
  }

  input[type='radio'] {
    display: none;

    + label {
      padding: 10px 20px;
      margin-right: 10px;
      background-color: rgb(218, 240, 238);
      border-width: 0;
      color: #000;
      cursor: pointer;
      border-radius: 10px;
      line-height: normal;
    }

    &:checked + label {
      background-color: rgb(19, 157, 175);
      color: #fff;
    }
  }
}

.customerType {
  border-width: 0;

  @media screen and (max-width: 1180px) {
    margin-bottom: 10px;
  }

  input[type='radio'] {
    display: none;

    + label {
      position: relative;
      margin-right: 10px;
      cursor: pointer;
      margin-left: 34px;
      border-radius: 10px;
      display: inline-block;
      margin-bottom: 10px;
      line-height: 1;

      &::after {
        content: attr(data-label);
      }

      &::before {
        content: '';
        position: absolute;
        left: -34px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border: 2px solid rgb(218, 240, 238);
        border-radius: 4px;
      }
    }

    &:checked + label::after {
      content: '';
      position: absolute;
      left: -26px;
      top: -2px;
      width: 6px;
      height: 12px;
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

textarea[name='content'] {
  width: calc(80% - 20px);
  height: 200px;
  padding: 10px;
  border: 2px solid rgb(218, 240, 238);
  resize: none;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 10px;
  font-size: 20px;

  @media screen and (max-width: 1180px) {
    width: 100%;
  }

  &::placeholder {
    color: #000;
  }

  &:focus {
    outline: none;
    border-color: rgb(19, 157, 175);
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    &-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &-thumb {
      background-color: cadetblue;
      outline: 1px solid cadetblue;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}

.text-success {
  text-align: center;
  color: greenyellow;
  font-weight: bold;
  margin-top: 20px;
  font-size: 28px;
}

.space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1180px) {
    margin-bottom: 20px;
  }
}
