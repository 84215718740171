/* This stylesheet generated by Transfonter (https://transfonter.org) on August 20, 2017 8:40 PM */

@font-face {
  font-family: 'Times New Roman';
  src: url('TimesNewRomanPS-ItalicMT.eot');
  src:
    local('Times New Roman Italic'),
    local('TimesNewRomanPS-ItalicMT'),
    url('TimesNewRomanPS-ItalicMT.eot?#iefix') format('embedded-opentype'),
    url('TimesNewRomanPS-ItalicMT.woff') format('woff'),
    url('TimesNewRomanPS-ItalicMT.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('TimesNewRomanPS-BoldItalicMT.eot');
  src:
    local('Times New Roman Bold Italic'),
    local('TimesNewRomanPS-BoldItalicMT'),
    url('TimesNewRomanPS-BoldItalicMT.eot?#iefix') format('embedded-opentype'),
    url('TimesNewRomanPS-BoldItalicMT.woff') format('woff'),
    url('TimesNewRomanPS-BoldItalicMT.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('TimesNewRomanPSMT.eot');
  src:
    local('Times New Roman'),
    local('TimesNewRomanPSMT'),
    url('TimesNewRomanPSMT.eot?#iefix') format('embedded-opentype'),
    url('TimesNewRomanPSMT.woff') format('woff'),
    url('TimesNewRomanPSMT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('TimesNewRomanPS-BoldMT.eot');
  src:
    local('Times New Roman Bold'),
    local('TimesNewRomanPS-BoldMT'),
    url('TimesNewRomanPS-BoldMT.eot?#iefix') format('embedded-opentype'),
    url('TimesNewRomanPS-BoldMT.woff') format('woff'),
    url('TimesNewRomanPS-BoldMT.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
