.profit {
  padding: 60px 0 100px;
  text-align: center;
  &__row {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    text-align: left;
  }
  &__col {
    width: calc(25% - 18px);
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 16px;
    &-img {
      width: 56px;
      height: 56px;
      margin-bottom: 16px;
    }
    &-text {
      @include text(normal, 16px, 20px, $black);
    }
  }
}

@media (max-width: 1024px) {
  .profit__col {
    width: calc(50% - 12px);
  }
  .profit {
    padding: 40px 0 80px;
  }
}

@media (max-width: 768px) {
  .profit {
    padding: 20px 0 60px;
  }
  .profit__col {
    display: flex;
    column-gap: 16px;
    align-items: center;
    padding: 15px;
    &-img {
      margin-bottom: 0;
      width: 40px;
      height: 40px;
    }
    &-text {
      line-height: 18px;
    }
  }
}

@media (max-width: 576px) {
  .profit__col {
    width: 100%;
  }
  .profit__row {
    row-gap: 16px;
  }
}
