.aboutApp {
  padding: 100px 0 116px;
  background: #f8f8f8;
  &__content {
    display: flex;
    column-gap: 130px;
    flex-wrap: wrap;
    align-items: center;
  }

  &__left {
    width: calc(50% - 65px);
  }
  &__right {
    width: calc(50% - 65px);
  }
  &__video {
    width: 100%;
  }

  &__subtitle {
    @include text(normal, 20px, 24px, $black);
    margin-bottom: 16px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-left: 30px;
    margin-bottom: 56px;
  }
  &__item {
    list-style-type: disc;
    @include text(normal, 20px, 24px, $black);
  }

  &__qrcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      text-align: center;
      @include text(normal, 14px, 18px, $black);
      margin-bottom: 16px;
    }
    &-img {
      width: 150px;
      height: 150px;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
    }
  }

  &__links {
    display: flex;
    column-gap: 40px;
  }
  &__apps {
    display: flex;
    column-gap: 15px;
    padding-top: 40px;
    &-img {
      max-width: 210px;
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .aboutApp__content {
    column-gap: 60px;
  }
  .aboutApp__right,
  .aboutApp__left {
    width: calc(50% - 30px);
  }
  .aboutApp__apps {
    flex-direction: column;
    row-gap: 15px;
  }
}

@media (max-width: 1024px) {
  .aboutApp {
    padding: 70px 0 70px;
  }
  .aboutApp__content {
    column-gap: 40px;
    .title {
      margin-bottom: 30px !important;
    }
  }
  .aboutApp__right,
  .aboutApp__left {
    width: calc(50% - 20px);
  }

  .aboutApp__qrcode {
    display: none;
  }
  .aboutApp__apps {
    flex-direction: row;
    &-img {
      max-width: 180px;
    }
  }
  .aboutApp__list {
    margin-bottom: 0;
  }
  .aboutApp__subtitle {
    font-size: 18px;
  }
  .aboutApp__item {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .aboutApp__apps {
    column-gap: 12px;
    padding-top: 25px;
    &-img {
      max-width: 160px;
    }
  }
}

@media (max-width: 820px) {
  .aboutApp__content .title {
    margin-bottom: 20px !important;
  }
  .aboutApp {
    padding: 55px 0 55px;
  }
}

@media (max-width: 768px) {
  .aboutApp__subtitle {
    font-size: 16px;
    line-height: 24px;
  }
  .aboutApp__item {
    font-size: 16px;
    line-height: 24px;
  }
  .aboutApp__list {
    padding-left: 15px;
  }
  .aboutApp__content {
    column-gap: 20px;
  }
  .aboutApp__right,
  .aboutApp__left {
    width: calc(50% - 10px);
  }
}
@media (max-width: 676px) {
  .aboutApp__content {
    flex-direction: column;
    row-gap: 20px;
  }
  .aboutApp__left,
  .aboutApp__right {
    width: 80%;
  }
  .aboutApp__list {
    padding-left: 30px;
  }
  .aboutApp__apps-img {
    max-width: 140px;
  }
}

@media (max-width: 425px) {
  .aboutApp__right,
  .aboutApp__left {
    width: 100%;
  }
}
