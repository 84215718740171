.request {
  background: #292929;
  padding: 58px 0 42px;

  &__title {
    @include text(bold, 60px, 72px, #fff);
    margin-bottom: 16px;
  }
  &__subtitle {
    @include text(normal, 20px, 28px, #fff);
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__form {
    max-width: 460px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__label {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
  }

  &__error {
    position: absolute;
    bottom: -22px;
    left: 10px;
    @include text(normal, 14px, 20px, $yellow);
  }

  &__check {
    display: grid;
    grid-template-columns: 18px auto;
    column-gap: 11px;
    margin-bottom: 35px;
    align-items: center;
    &-text {
      @include text(normal, 12px, 16px, #f8f8f8);
    }
  }
  &__checkbox {
    accent-color: #ffd335;
    width: 18px;
    height: 18px;
  }
  &__input {
    @include text(normal, 20px, 24px, #919191);
    width: 100%;
    height: 56px;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    padding-left: 20px;
  }
  &__btn {
    @include text(600, 20px, 24px, #02136a);
    position: relative;

    overflow: hidden;

    height: 56px;

    cursor: pointer;
    transition: all 0.7s;

    border: 1px solid #ffd335;
    border-radius: 8px;
    background: #ffd335;
  }
}

.btn:hover {
  z-index: 0;

  color: #ffd335;
  border: 2px solid #ffd335;
}

.btn span {
  z-index: -1;

  transition: all 0.7s;
}

.btn .first {
  position: absolute;
  top: 0;
  right: 100%;

  width: 25%;
  height: 100%;

  content: '';

  background: #fff;
}

.btn:hover .first {
  top: 0;
  right: 0;
}

.btn .second {
  position: absolute;
  top: -100%;
  left: 25%;

  width: 25%;
  height: 100%;

  content: '';

  background: #fff;
}

.btn:hover .second {
  top: 0;
  left: 50%;
}

.btn .third {
  position: absolute;
  top: 100%;
  left: 50%;

  width: 25%;
  height: 100%;

  content: '';

  background: #fff;
}

.btn:hover .third {
  top: 0;
  left: 25%;
}

.btn .fourth {
  position: absolute;
  top: 0;
  left: 100%;

  width: 25%;
  height: 100%;

  content: '';

  background: #fff;
}

.btn:hover .fourth {
  top: 0;
  left: 0;
}

@media (max-width: 1200px) {
  .request__title {
    font-size: 44px;
    line-height: 52px;
  }
  .request__form {
    max-width: 420px;
  }
  .request__subtitle {
    font-size: 18px;
    line-height: 24px;
  }
  .request__input {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .request__label {
    margin-bottom: 25px;
  }
  .request__check {
    margin-bottom: 30px;
  }
  .request__form {
    max-width: 410px;
  }
  .request__error {
    position: absolute;
    bottom: -20px;
    left: 8px;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #ffd335;
  }
}

@media (max-width: 992px) {
  .request {
    padding: 40px 0 45px;
  }
  .request__form {
    max-width: 370px;
  }
  .request__title {
    font-size: 36px;
    line-height: 44px;
  }
  .request__subtitle {
    font-size: 17px;
    line-height: 23px;
  }
  .request__input {
    height: 50px;
    font-size: 17px;
  }
  .request__btn {
    font-size: 17px;
    height: 50px;
  }
  .request__label {
    margin-bottom: 25px;
  }
  .request__check {
    margin-bottom: 25px;
  }
}

@media (max-width: 820px) {
  .request__form {
    max-width: 336px;
  }
  .request__input {
    height: 40px;
    font-size: 16px;
  }
  .request__title {
    font-size: 34px;
    line-height: 42px;
  }
  .request__subtitle {
    font-size: 16px;
    line-height: 24px;
    max-width: 300px;
  }
  .request__btn {
    height: 40px;
  }
}

@media (max-width: 768px) {
  .request {
    padding: 20px 0 40px;
  }
  .request__input {
    font-size: 14px;
    line-height: 24px;
  }
  .request__title {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 676px) {
  .request__form {
    max-width: 300px;
  }
  .request__title {
    font-size: 28px;
    line-height: 32px;
  }
  .request__left {
    margin-right: 20px;
  }
  .request__error {
    position: absolute;
    bottom: -17px;
    left: 6px;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #ffd335;
  }
  .request__label {
    margin-bottom: 22px;
  }
}

@media (max-width: 576px) {
  .request__row {
    flex-direction: column;
  }
  .request__subtitle {
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .request__title {
    font-size: 24px;
    line-height: 28px;
  }
}
