.firstScreen {
  padding: 105px 0 0;
  background: #edf8f7;

  &__title {
    @include text(bold, 88px, 90px, #000);
  }
  &__subtitle {
    @include text(600, 32px, 40px, $black);
    margin: 24px 0;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    position: relative;
    max-height: 680px;
  }

  &__left {
    margin: 179px 0 184px;
  }

  &__circle {
    top: 0;
    right: -204px;
    position: absolute;
    width: 835px;
    height: 100%;
    background: url('../../../assets/home/Ellipse1.png');
  }
  &__img {
    height: 100%;
    position: absolute;
    right: -10px;
    top: 0;
  }
}

@media (max-width: 1750px) {
  .firstScreen {
    overflow: hidden;
  }
}

@media (max-width: 1440px) {
  .firstScreen__circle {
    right: -235px;
  }
  .firstScreen__img {
    right: -22px;
  }
}

@media (max-width: 1320px) {
  .firstScreen__circle {
    right: -288px;
  }
  .firstScreen__img {
    right: -60px;
  }
}

@media (max-width: 1200px) {
  .firstScreen__left {
    margin: 160px 0 184px !important;
  }
  .firstScreen__title {
    font-size: 78px;
    line-height: 78px;
  }
  .firstScreen__circle {
    width: 554px;
    right: -92px;
    height: 554px;
    top: 125px;
  }
  .firstScreen__img {
    right: -108px;
    top: 25px;
  }
  .firstScreen__row {
    max-height: 650px;
  }
}
@media (max-width: 1024px) {
  .firstScreen__row {
    max-height: 620px;
  }
  .firstScreen__left {
    margin: 110px 0 120px !important;
  }
  .firstScreen__img {
    right: -90px;
  }
  .firstScreen__title {
    font-size: 78px;
    line-height: 78px;
  }
  .firstScreen__circle {
    width: 554px;
    right: -135px;
    height: 554px;
    top: 125px;
  }
}

@media (max-width: 992px) {
  .firstScreen {
    padding: 70px 0 0;
    background: #edf8f7;
  }
  .firstScreen__row {
    max-height: 580px;
  }
  .firstScreen__left {
    margin: 90px 0 120px !important;
  }

  .firstScreen__subtitle {
    font-size: 28px;
    width: 300px;
  }
  .firstScreen__img {
    right: -27px;
  }
  .firstScreen__circle {
    right: -130px;
  }
  .firstScreen__title {
    font-size: 59px;
    line-height: 59px;
  }
}

@media (max-width: 820px) {
  .firstScreen__title {
    font-size: 47px;
    line-height: 49px;
  }
}

@media (max-width: 768px) {
  .firstScreen__circle {
    background: url('../../../assets/home/smallEllipse.png') no-repeat;
    right: -213px;
    top: 90px;
  }
  .firstScreen__img {
    height: 500px;

    right: -43px;
    top: 80px;
  }
}

@media (max-width: 676px) {
  .firstScreen__img {
    height: 440px;
    top: 43px;
  }
  .firstScreen__circle {
    right: -300px;
  }
  .firstScreen__row {
    max-height: 480px;
  }
  .firstScreen__title {
    font-size: 37px;
    line-height: 42px;
  }
  .firstScreen__subtitle {
    font-size: 26px;
    line-height: 32px;
  }
  .downloadButton {
    font-size: 18px;
    line-height: 20px;
  }
  .firstScreen {
    padding: 40px 0 0;
  }
}

@media (max-width: 576px) {
  .firstScreen__row {
    max-height: 448px;
  }
  .firstScreen__img {
    height: 333px;
    top: 115px;
    right: -40px;
  }
  .firstScreen__circle {
    right: -366px;
  }
  .firstScreen {
    padding: 10px 0 0;
  }
}

@media (max-width: 500px) {
  .firstScreen__title {
    font-size: 32px;
    line-height: 26px;
  }
  .firstScreen__subtitle {
    font-size: 17px;
    line-height: 22px;
    margin-top: 16px;
    margin-bottom: 310px;
  }
  .firstScreen__circle {
    width: calc(100% + 48px);
    background: url('../../../assets/home/smallEllipse2.png') no-repeat;
    background-size: 100% 281px;
    height: 281px;
    left: -24px;
    top: 303px;
  }
  .firstScreen__img {
    left: 50%;
    transform: translateX(-50%);
    top: 195px;
  }
  .firstScreen__row {
    max-height: 590px;
  }
  .firstScreen {
    .downloadButton {
      position: absolute;
      bottom: -1px;
      left: -24px;
      border: none;
      width: calc(100% + 48px);
      border-radius: 0;
      z-index: 6;
      background: $yellow;
      @include text(bold, 16px, 24px, #141414);
    }
  }
}
