.notFound {
  width: 100%;
  height: 100vh;
  background: #edf8f7;
  display: flex;
  justify-content: center;
  align-items: center;
  &__block {
    width: 80%;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: #1f1f29;
    padding: 64px 0 80px;

    align-items: center;
  }
  &__title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 180px;
    line-height: 218px;

    letter-spacing: -0.5px;

    /* Text/txt-primary */

    color: rgba(255, 255, 255, 0.9);
  }
  &__subtitle {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 77px;

    color: rgba(255, 255, 255, 0.75);
    margin-bottom: 30px;
  }
  &__btn {
    padding: 20px 20px;
    border-radius: 5px;
    border: none;
    transition: 0.3s;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    &:hover {
      transition: 0.3s;
      background: #edf8f7;
      cursor: pointer;
    }
  }
}

@media (max-width: 992px) {
  .notFound__title {
    font-size: 150px;
    line-height: 180px;
  }
  .notFound__subtitle {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (max-width: 768px) {
  .notFound__title {
    font-size: 140px;
    line-height: 169px;
  }
  .notFound__subtitle {
    font-size: 30px;
    line-height: 38px;
  }
  .notFound__btn {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .notFound__block {
    width: 100%;
  }
  .notFound__title {
    font-size: 120px;
    line-height: 149px;
  }
  .notFound__subtitle {
    font-size: 25px;
    line-height: 28px;
  }
  .notFound__btn {
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  .notFound__title {
    font-size: 100px;
    line-height: 120px;
  }
  .notFound__subtitle {
    font-size: 23px;
    line-height: 25px;
  }
}

@media (max-width: 375px) {
  .notFound__title {
    font-size: 90px;
    line-height: 110px;
  }
  .notFound__subtitle {
    font-size: 22px;
    line-height: 24px;
  }
  .notFound__btn {
    padding: 10px;
  }
}
