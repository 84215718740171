.pay {
  padding: 100px 0 120px;
  &__sum {
    @include text(600, 24px, 32px, #000);
    margin-top: 42px;
    text-align: right;
  }
  &__content {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 40px;
  }
  &__row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 0;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    &_end {
      justify-content: flex-end;
    }
    &_rem {
      .pay__card-money {
        color: #fff;
      }
    }
    &_end {
      .pay__card-price:nth-child(3) {
        border-radius: 10px 0 0 10px;
      }
    }
  }
  &__card {
    width: 14.3%;
    text-align: center;
    &-img {
      width: 130px;
    }
    &-desc {
      &-title {
        @include text(600, 24px, 32px, #02136a);
      }
      &-price {
        @include text(600, 24, 32px, $black);
      }
      &_sneakers {
        margin-left: 30px;
      }
    }
    &-month {
      @include text(normal, 16px, 24px, #6e6e6e);
      text-transform: uppercase;
      margin-bottom: 4px;
    }
    &-money {
      @include text(600, 24px, 32px, #000);
    }

    &_2 {
      width: 28.6%;
      text-align: left;
      @include text(600, 24px, 32px, #000);
      text-transform: uppercase;
    }
    &_flex {
      text-transform: none;
      display: flex;
      align-items: center;
    }
    &-price {
      padding: 20px 0;
      &:nth-child(2) {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }
}

@media (max-width: 1200px) {
  .pay {
    padding: 85px 0 100px;
  }
  .pay__content {
    padding: 20px;
  }
  .pay__card-desc_sneakers {
    margin-left: 10px;
  }
  .pay__card-img {
    width: 120px;
  }
}

@media (max-width: 1024px) {
  .pay {
    padding: 70px 0 80px;
  }
  .pay__card-desc_sneakers {
    margin-left: 5px;
  }
}

@media (max-width: 992px) {
  .pay__content {
    padding: 10px;
  }
  .pay__row {
    padding: 10px 0;
    &_end {
      .pay__card:nth-child(2) {
        order: -1;
      }
    }
  }
  .pay__card-img {
    width: 90px;
  }
  .pay__sum {
    margin-top: 25px;
  }
}

@media (max-width: 820px) {
  .pay {
    padding: 60px 0 60px;
  }
  .pay__card_2 {
    font-size: 22px;
  }
  .pay__card-money {
    font-size: 22px;
  }
  .pay__sum {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .pay {
    padding: 20px 0 40px;
  }
  .pay {
    .title {
      margin-bottom: 40px !important;
    }
  }
  .pay__content {
    padding: 0;
    border: none;
    box-shadow: none;
  }
  .pay__card_2 {
    font-size: 16px;
    line-height: 24px;
  }
  .pay__card-month {
    font-size: 14px;
    line-height: 20px;
  }
  .pay__card-money {
    font-size: 16px;
    line-height: 24px;
  }
  .pay__card-price {
    padding: 10px;
  }
  .pay__card-img {
    width: 55px;
  }
  .pay__card-desc-title {
    font-size: 16px;
    line-height: 20px;
  }
  .pay__card_desc-price {
    font-size: 16px;
    line-height: 24px;
  }
  .pay__sum {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 500px) {
  .pay__table {
    display: flex;
  }
  .pay__row {
    width: 25%;
    flex-direction: column;
    &:first-child {
      row-gap: 20px;
      .pay__card_2 {
        margin-bottom: 0;
      }
    }
  }
  .pay__card {
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-variant: small-caps;

    line-height: 24px;
    &-price {
      width: 70%;
      text-align: center;
      &:nth-child(2) {
        border-radius: 10px 10px 0 0;
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }
  .pay__row:nth-child(3) {
    .pay__card {
      &-price {
        &:nth-child(3) {
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  .pay__card-month {
    margin-bottom: 0;
  }
  .pay__card-img {
    display: none;
  }
  .pay__card-price {
    padding: 10px 0 26px;
  }
  .pay__card_2 {
    justify-content: center;
    margin-bottom: 16px;
    align-items: center;
    display: flex;
    text-align: center;
    height: 56px;
    border-bottom: 1px solid #c8c8c8;
  }
  .pay__row_end {
    justify-content: flex-start;
    .pay__card_2:first-child {
      height: 104px;
      border-bottom: 0;
    }
  }
  .pay__row_end .pay__card-price:nth-child(3) {
    border-radius: 10px 10px 0 0;
  }
  .pay__card-desc-title {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .pay__card-desc-price {
    font-size: 14px;
    line-height: 20px;
  }
  .pay__row:last-child {
    border-bottom: 1px solid #e1e1e1;
  }
  .pay__sum {
    text-align: left;
    margin-top: 16px;
  }
  .pay__card-month {
    font-variant: small-caps;
  }
}

@media (max-width: 425px) {
  .pay {
    .title {
      margin-bottom: 30px !important;
    }
  }
}
