.header {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
  top: 34px;

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__right {
    display: flex;
    column-gap: 105px;
    align-items: center;
  }
  &__menu {
    display: flex;
    column-gap: 24px;
    list-style-type: none;
  }
  &__link {
    @include text(600, 16px, 24px, $black);
    a {
      color: #404040;
    }
  }
}
.burger {
  display: none;
}

@media (max-width: 1440px) {
  .header__right {
    column-gap: 80px;
    z-index: 5;
  }
}
@media (max-width: 1320px) {
  .header__menu {
    column-gap: 15px;
  }
  .header__right {
    column-gap: 50px;
  }
}

@media (max-width: 1200px) {
  .header__menu {
    align-items: center;
    text-align: center;
  }
  .header__right {
    column-gap: 35px;
  }
  .header__link {
    line-height: 20px;
  }
  .header__title {
    margin-right: 20px;
  }
}

@media (max-width: 992px) {
  .header {
    top: 30px;
    z-index: 2;
  }
  .header__link {
    a {
      color: #fff;
    }
  }
  .burger {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    &__active {
      .burger__line {
        height: 0;
        transition: 0s;
        &::before {
          background: white;
          transform: rotate(-45deg);
          top: 0;
          transition: 0.3s;
        }
        &::after {
          background: white;
          transform: rotate(45deg);
          top: 0;
          transition: 0.3s;
        }
      }
    }
    &__line {
      width: 33.33px;
      height: 3.33px;
      background: black;
      border-radius: 4px;
      position: relative;
      transition: 0.3s;
      &::before {
        position: absolute;
        content: '';
        width: 33.33px;
        height: 3.33px;
        background: black;
        border-radius: 4px;
        top: -10px;
        left: 0;
        transition: 0.3s;
      }
      &::after {
        position: absolute;
        content: '';
        width: 33.33px;
        height: 3.33px;
        background: black;
        border-radius: 4px;
        top: 10px;
        left: 0;
        transition: 0.3s;
      }
    }
  }
  .header__right {
    position: absolute;
    display: flex;
    width: 100%;
    background: $green;
    left: 0;
    top: -145px;
    height: 110px;
    z-index: 9;
    align-items: center;
    justify-content: space-between;
    padding-right: 100px;
    padding-left: 30px;
    transition: 0.5s;
    border-radius: 0 0 50px 50px;
    .header__link {
      color: white;
      transition: 0.5s;
    }
  }
  .header__right_active {
    top: -34px;
    transition: 0.5s;
  }
}

@media (max-width: 820px) {
  .header__menu {
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 20px;
  }
}

@media (max-width: 676px) {
  .header__right {
    width: 50%;
    right: 0;
    left: unset;
    top: -545px;
    z-index: 7;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 0 0 0 20px;
    row-gap: 20px;
  }
  .header__right_active {
    top: -34px;
  }
  .header__menu {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .header__right {
    height: 400px;
  }
}
@media (max-width: 500px) {
  .header__right {
    width: 60%;
  }
}
@media (max-width: 425px) {
  .header__right {
    width: 65%;
  }
}

@media (max-width: 375px) {
  .header__right {
    width: 75%;
  }
}
