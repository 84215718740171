.firstScreenMobile {
  padding: 57px 0 40px;
  &__block {
    background: #3530fa;
    border-radius: 28px;
    padding: 24px 11px 20px 16px;
    position: relative;
    margin-bottom: 40px;

    &-bg {
      width: 100%;
      position: absolute;
      bottom: -2px;
      left: 0;
      border-radius: 0 0 25px 25px;
    }
  }
  &__title2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #21222b;
  }
  &__subtitle2 {
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: #21222b;
    margin: 10px 0 20px;
  }
  &__item {
    width: calc(100%);
    img {
      width: 100%;
    }
  }
  &__img {
    width: 100%;
    position: relative;
    z-index: 2;
  }
  &__subtitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #ffffff;
    margin: 9px 0 20px;
  }
  .swiper__cont_2 {
    padding-left: 20px;
  }
  .swiper-slide {
    width: 90% !important;
  }
}
