.whereUse {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #21222b;
  }
  &__subtitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: #21222b;
    margin: 10px 0 20px;
  }
  &__title-swipe {
    font-weight: 700;
    font-size: 22px;
    line-height: 100%;
    color: #060606;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 20px;
  }

  .swiper-button-prev {
    display: none;
  }

  &__swiper {
    position: relative;
  }
  &__swiper2 {
    margin-top: 31px;
  }

  .swiper-button-next {
    top: 10px !important;
    &::after {
      content: url('./arrow.svg');
    }
  }
  .swiper {
    padding-top: 45px;
  }
  .swiper-slide {
    width: 40% !important;
  }
  .whereUse__swiper {
    padding-left: 20px;
  }

  .whereUse__swiper2 {
    .swiper-slide {
      width: 35% !important;
    }
  }
}
