.work {
  padding: 40px 0 80px;
  text-align: center;
  &__row {
    display: flex;
    column-gap: 24px;
  }
  &__col {
    width: calc(25% - 18px);
    &-img {
      width: 100%;
      margin-bottom: 20px;
    }
    &-info {
      display: grid;
      width: 100%;
      height: auto;
      grid-template-columns: 32px auto;
      grid-column-gap: 16px;
      -moz-column-gap: 16px;
      column-gap: 16px;
    }
    &-num {
      width: 32px;
      height: 32px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #c8c8c8;
      @include text(600, 20px, 24px, #6e6e6e);
    }
    &-text {
      text-align: left;
      @include text(normal, 18px, 20px, $black);
    }
  }
}

@media (max-width: 1024px) {
  .work__row {
    flex-wrap: wrap;
    row-gap: 40px;
    justify-content: center;
  }
  .work__col {
    width: calc(40% - 12px);
  }
}

@media (max-width: 1024px) {
  .work__col {
    width: calc(45% - 12px);
  }
}

@media (max-width: 768px) {
  .work {
    padding: 30px 0 60px;
  }
  .work__col {
    width: calc(50% - 12px);
  }
}

@media (max-width: 576px) {
  .work {
    padding: 20px 0 40px;
  }
  .work__col {
    width: 70%;
  }
}

@media (max-width: 500px) {
  .work__col {
    width: 85%;
  }
}

@media (max-width: 425px) {
  .work__col {
    width: 100%;
  }
}
