.downloadButton {
  @include text(bold, 20px, 24px, #fff);
  padding: 20px;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 6px;
  background: $green;
  cursor: pointer;
  border: 1px solid $green;
  position: relative;
  overflow: hidden;
  transition: all 0.7s;
  &_mobile {
    display: none;
  }
  &:hover {
    z-index: 0;
    color: $green;
    border: 1px solid $green;
  }
  span {
    z-index: -1;

    transition: all 0.7s;
  }
  .first {
    position: absolute;
    top: 0;
    right: 100%;
    width: 25%;
    height: 100%;
    content: '';
    background: #edf8f7;
  }
  &:hover .first {
    top: 0;
    right: 0;
  }

  .second {
    position: absolute;
    top: -100%;
    left: 25%;

    width: 25%;
    height: 100%;

    content: '';

    background: #edf8f7;
  }

  &:hover .second {
    top: 0;
    left: 50%;
  }

  .third {
    position: absolute;
    top: 100%;
    left: 50%;

    width: 25%;
    height: 100%;

    content: '';

    background: #edf8f7;
  }

  &:hover .third {
    top: 0;
    left: 25%;
  }
  .fourth {
    position: absolute;
    top: 0;
    left: 100%;

    width: 25%;
    height: 100%;

    content: '';

    background: #edf8f7;
  }

  &:hover .fourth {
    top: 0;
    left: 0;
  }
}

@media (max-width: 992px) {
  .downloadButton {
    &_desktop {
      display: none;
    }
    &_mobile {
      display: inline-block;
      text-align: center;
    }
  }
}
