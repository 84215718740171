.requistes {
  max-width: 800px;
  margin: 0 auto;
  .margin-req {
    padding-top: 30px;
  }
  p {
    margin: 16px 0;
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    font-size: 15px;
    font-family: 'Arial', sans-serif;
    font-style: normal;
  }

  td,
  th {
    border: 1px solid #000;
    padding: 10px 10px;
  }
  table {
    border-spacing: 0px;
    margin-bottom: 10px;
  }
  .responsive-table {
    overflow-x: auto;
  }
  @media (max-width: 425px) {
    td,
    th {
      padding: 5px 5px;
      font-size: 15px;
    }
  }
}
