/* This stylesheet generated by Transfonter (https://transfonter.org) on August 20, 2017 5:39 PM */

@font-face {
  font-family: 'Arial';
  src: url('Arial-ItalicMT.eot');
  src:
    local('Arial Italic'),
    local('Arial-ItalicMT'),
    url('Arial-ItalicMT.eot?#iefix') format('embedded-opentype'),
    url('Arial-ItalicMT.woff') format('woff'),
    url('Arial-ItalicMT.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('ArialNarrow-Bold.eot');
  src:
    local('Arial Narrow Bold'),
    local('ArialNarrow-Bold'),
    url('ArialNarrow-Bold.eot?#iefix') format('embedded-opentype'),
    url('ArialNarrow-Bold.woff') format('woff'),
    url('ArialNarrow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Arial';
  src: url('Arial-BoldItalicMT.eot');
  src:
    local('Arial Bold Italic'),
    local('Arial-BoldItalicMT'),
    url('Arial-BoldItalicMT.eot?#iefix') format('embedded-opentype'),
    url('Arial-BoldItalicMT.woff') format('woff'),
    url('Arial-BoldItalicMT.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('ArialNarrow.eot');
  src:
    local('Arial Narrow'),
    local('ArialNarrow'),
    url('ArialNarrow.eot?#iefix') format('embedded-opentype'),
    url('ArialNarrow.woff') format('woff'),
    url('ArialNarrow.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('ArialNarrow-Italic.eot');
  src:
    local('Arial Narrow Italic'),
    local('ArialNarrow-Italic'),
    url('ArialNarrow-Italic.eot?#iefix') format('embedded-opentype'),
    url('ArialNarrow-Italic.woff') format('woff'),
    url('ArialNarrow-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Arial';
  src: url('ArialMT.eot');
  src:
    local('Arial'),
    local('ArialMT'),
    url('ArialMT.eot?#iefix') format('embedded-opentype'),
    url('ArialMT.woff') format('woff'),
    url('ArialMT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial';
  src: url('Arial-BoldMT.eot');
  src:
    local('Arial Bold'),
    local('Arial-BoldMT'),
    url('Arial-BoldMT.eot?#iefix') format('embedded-opentype'),
    url('Arial-BoldMT.woff') format('woff'),
    url('Arial-BoldMT.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Arial';
  src: url('Arial-Black.eot');
  src:
    local('Arial Black'),
    local('Arial-Black'),
    url('Arial-Black.eot?#iefix') format('embedded-opentype'),
    url('Arial-Black.woff') format('woff'),
    url('Arial-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('ArialNarrow-BoldItalic.eot');
  src:
    local('Arial Narrow Bold Italic'),
    local('ArialNarrow-BoldItalic'),
    url('ArialNarrow-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('ArialNarrow-BoldItalic.woff') format('woff'),
    url('ArialNarrow-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
