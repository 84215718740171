.mobileAppRules {
  font-family: 'Arial', sans-serif !important;
  font-style: normal !important;
  width: 100%;
  max-width: 794px;
  display: flex;
  flex-direction: column;
  line-height: 1.15 !important;
}

.mobileAppRules__mainTitle {
  font-family: 'Arial', sans-serif !important;
  font-style: normal !important;
  font-size: 18pt !important;
  font-weight: 400;
  line-height: 26pt !important;
}

.mobileAppRules span {
  font-family: 'Arial', sans-serif !important;
  font-style: normal !important;
}
.mobileAppRules p {
  font-family: 'Arial', sans-serif !important;
  font-style: normal !important;
}
.mobileAppRules__bolderTitle {
  font-family: 'Arial', sans-serif !important;
  font-weight: bolder;
}

.mobileAppRules ul {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.mobileAppRules__ulNowGap {
  display: flex;
  flex-direction: column;
  row-gap: 5px !important;
}

.mobileAppRules__olBigTitle {
  font-weight: 700;
  font-size: 17pt;
  margin-top: 50px;
  margin-bottom: 15px;
}

.mobileAppRules__olInOl {
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  padding-left: 30px;
}

.mobileAppRules__endTitle {
  margin-top: 60px;
  font-size: 12pt;
  font-weight: 700;
}
