.howStart {
  padding: 45px 0;
  &__block1 {
    margin-bottom: 45px;
    position: relative;
  }
  &__img {
    width: 100%;
  }
  &__block1 {
    margin-bottom: 45px;
  }
  &__btn {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #060606;
  }
  &__subtitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: #000000;
    margin: 10px 0 20px;
  }
}
