@import 'fonts/Segoe/stylesheet.css';
@import 'fonts/Arial/stylesheet.css';
@import 'fonts/TimesNewRoman/stylesheet.css';
@import 'fonts/Benzin-bold/style.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  text-decoration: none;

  scroll-behavior: smooth;
}
@mixin text($fw, $fz, $lh, $col) {
  font-weight: $fw;
  font-size: $fz;
  line-height: $lh;
  color: $col;
}

$black: #404040;
$green: #48b3ab;
$yellow: #ffd335;

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 60px;
  margin: 0 auto;
}

@import 'Layout/Header/header';
@import 'Layout/Header/switchLang/switchLang';

@import 'pages/Home/Som/Som';
@import 'pages/Home/DownloadButton/downloadButton';
@import 'pages/Home/Title/title';
@import 'pages/Home/home';
@import 'pages/Home/FirstScreenHome/firstScreenHome';
@import 'pages/Home/Terms/terms';
@import 'pages/Home/Issue/issue';
@import 'pages/Home/Pay/pay';
@import 'pages/Home/Buy/buy';
@import 'pages/Home/Buy/BuySwiper/buySwiper';
@import 'pages/Home/Questions/questions';
@import 'pages/Home/AboutApp/aboutApp';
@import 'pages/Home/Repay/repay';
@import 'pages/Home/FormBlock/formBlock';

@import 'pages/Business/FirstScreenBusiness/firstScreenBusiness';
@import 'pages/Business/Trust/trust';
@import 'pages/Business/Work/work';
@import 'pages/Business/Help/help';
@import 'pages/Business/Profit/profit';
@import 'pages/Business/Request/request';

@import 'pages/Docs/docs';
@import 'pages/Docs/Doc/Requistes/requistes';
@import 'pages/Docs/Doc/MobileApplication/mobileAppRules';
@import 'pages/Docs/Doc/PersonalInfoAndSubject/persInfoAndSubj';
@import 'pages/Docs/Doc/PersonalInfoAndSubject/docStyles';
@import 'pages/Docs/Archive/ArchiveFirstPoint/firstPoint';
@import 'pages/Docs/Doc/PublicCreditContract/PublicCreditContract';
@import 'pages/Docs/Doc/PublicOffer/PublicOffer';
@import 'pages/Docs/Doc/Rules/rules';
@import 'pages/Docs/Doc/UsersInfo/userInfo';

@import 'pages/NotFound/notFound';

@import 'Layout/Footer/footer';

@media (max-width: 1320px) {
  .container {
    padding: 0 40px;
  }
}
@media (max-width: 1024px) {
  .container {
    padding: 0 30px;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 0 24px;
  }
}

@media (max-width: 375px) {
  .container {
    padding: 0 20px;
  }
}
